actions:
  alerts: Warnungen
  new_goal: Neues Ziel
  pin_column: Anheften
  quick_actions: Schnellaktionen
  report_alert: Melden
  report_alert_description: >-
    Geben Sie die Details der Meldung an und wählen Sie die betroffenen Personen
    aus:
  unpin_column: Lösen
alerts:
  add_comment: Kommentieren Sie den Alarm oder fügen Sie Details hinzu...
  alert_center: Alarmzentrum
  assigned_to: hat den Alarm zugewiesen an
  comment: Kommentar
  get_more: Mehr laden + { count } Alarm | Mehr laden + { count } Alarme
  information_about_alerts: Mehr Informationen über den Alarm...
  justification: Rechtfertigung
  load_more: Mehr laden
  minimum_one_justification: Sie müssen eine Rechtfertigung angeben
  minimum_one_selected_people: Mindestens eine Person muss dem Alarm zugewiesen werden
  my_interventions: Meine Interventionen
  new_alert: Neuer Alarm
  no_justification_provided: Keine Rechtfertigung angegeben
  no_saved_answers: Keine vorher gespeicherten Antworten
  people_to_alert: 'Personen oder Gruppen zu alarmieren:'
  raised_by_me: Von mir ausgelöst
  removed_from: wurde entfernt von
  search_placeholder: Name des Datensatzes suchen
  select_users_to_alert: Wählen Sie die Person(en) aus, die alarmiert werden sollen
  status_history: Verlauf der Alarmstatus
  to_signal: 'Zu melden:'
analysis:
  analysis: Analyse
application_fields:
  all_operations: Alle Operationen
  all_operations_search: 'Alle Operationen enthalten: { entry }'
  all_products: Alle Produkte
  all_products_search: 'Alle Produkte enthalten: { entry }'
  all_workplaces: Alle Arbeitsplätze
  all_workplaces_search: 'Alle Arbeitsplätze enthalten: { entry }'
  application_fields: Anwendungsfelder
  charging-station: Ladeplatz
  operations: Operationen
  product: Produkt
  products: Produkte
  reference: Referenzen
  select_all_operations: Alle Operationen auswählen
  select_all_products: Alle Produkte auswählen
  select_all_workplaces: Alle Arbeitsplätze auswählen
  select_operation: Operation auswählen...
  select_product: Produkt auswählen...
  select_workplace: Arbeitsplatz auswählen...
  workplaces: Arbeitsplätze
batch_record:
  batch_record_created: Das Dokument wurde erstellt und den ausgewählten Produktionsaufträgen beigefügt.
  batch_record_creation_error: Beim Erstellen des Dokuments ist ein Fehler aufgetreten.
  add_comment: Kommentar hinzufügen
  batch: Fertigungsauftrag
  batch_sumup: Fertigungsauftragszusammenfassung anzeigen
  delete_document_from_batch_description: >-
    Sind Sie sicher, dass Sie diesen Anhang löschen möchten? Diese Aktion ist
    nicht rückgängig zu machen.
  delete_document_from_batch_title: Anhang löschen
  documents: Dokumente des Fertigungsauftrags
  information: Informationen zum Fertigungsauftrag
  last_released_by_title: Letzte Freigabe des Fertigungsauftrags
  modal_release_description: >-
    Sie sind dabei, den Fertigungsauftrag freizugeben. Stellen Sie sicher, dass
    der Fertigungsauftrag vollständig konform ist, bevor Sie fortfahren. Bitte
    geben Sie Ihre Anmeldeinformationen ein, um zu bestätigen.
  modal_release_title: Sind Sie sicher, dass Sie den Fertigungsauftrag { name } freigeben möchten?
  new_document: Neues Dokument hinzufügen
  release: Freigeben
  release_batch_login_error: Falsche Anmeldeinformationen.
  release_batch_rights_error: Sie haben nicht die nötigen Rechte, um diese Aktion durchzuführen.
  release_title: Freigabe des Fertigungsauftrags
  release_title_sidebar: Freigabe von Chargen
  released_batch: Fertigungsauftrag freigegeben am { date } von
  update_document: Dokument aktualisieren
  update_report: Bericht aktualisieren
  update_attachment: Anhang aktualisieren
bread_crumb:
  dokumente: Alle Dokumente
  new_view: Neue Ansicht
  production_orders: Alle Fertigungsaufträge
  reports: Alle Berichte
  simulation: Fertigungsauftrag simulieren
  vorlagen: Alle Vorlagen
  views: Alle Ansichten
button:
  about: Über
  back: Zurück
  go: GEHEN
  home: Startseite
  toggle_dark: Dunkelmodus umschalten
  toggle_langs: Sprache ändern
dashboard:
  empty_dashboard: Kein Dashboard verfügbar.
  last_30_days: Die letzten 30 Tage.
  select: Wählen Sie ein Dashboard aus
  select_dashboard_placeholder: Auswählen
dates:
  day: vor einem Tag | vor {amount} Tagen
  hour: vor einer Stunde | vor {amount} Stunden
  minute: vor einer Minute | vor {amount} Minuten
  month: vor einem Monat | vor {amount} Monaten
  second: vor einer Sekunde | vor {amount} Sekunden
days:
  daily: Täglich
  friday: Freitag
  monday: Montag
  saturday: Samstag
  sunday: Sonntag
  thursday: Donnerstag
  tuesday: Dienstag
  wednesday: Mittwoch
display:
  all_columns: Alle Spalten
  all_lines: Alle Zeilen
  editable_columns: Bearbeitbare Spalten
  editable_lines: Bearbeitbare Zeilen
document:
  add_new_block: Neuen Block hinzufügen
  application_field: Anwendungsfeld
  archive: Dokument archivieren
  starts_with_report_launch: Der Moment des Einsatzbeginns
  archive_confirmation_message: Sind Sie sicher, dass Sie das Dokument archivieren möchten?
  ask_admin: Bitte wenden Sie sich an den Anwendungsadministrator
  cant_finish_document: Es müssen noch Eingaben gemacht werden
  cant_finish_document_description: >-
    Sie können dieses Dokument nicht abschließen, ohne die Zellen auf den
    obligatorischen (nicht optionalen) Zeilen ausgefüllt zu haben. Sie können
    jedoch "N/A" in die betreffenden Zellen eingeben oder sie markieren.
  cant_finish_document_description_mandatory_lines: 'Die folgenden Zeilen sind obligatorisch:'
  category: Kategorie
  cells_missing: '{number} Zelle(n) fehlt/fallen'
  column_number: Spaltennummer
  columns: Spalten
  confirm_archive_in_document:
    description: >-
      Sie sind dabei, dieses Dokument zu archivieren. Nach der Archivierung
      steht dieses Dokument nicht mehr zur Verwendung im Fertigungsauftrag zur
      Verfügung. Sind Sie sicher, dass Sie fortfahren möchten?
    title: Archivierung bestätigen
  confirm_archive_many_documents:
    description: >-
      Sie sind dabei, 1 Dokument zu archivieren. Dieses Dokument kann nicht mehr
      im Fertigungsauftrag verwendet werden. | Sie sind dabei, {count} Dokumente
      zu archivieren. Diese Dokumente können nicht mehr im Fertigungsauftrag
      verwendet werden.
    title: Archivierung bestätigen
  create: Neues Dokument
  create_new_document: Speichern bestätigen?
  create_new_document_message: Sie sind dabei, die erste Version dieses neuen Dokuments zu erstellen.
  create_new_version: Neue Version erstellen?
  create_new_version_message: >-
    Sie sind dabei, die Version dieses Dokuments zu ändern, indem Sie es
    speichern
  created_by: Erstellt von
  created_on: Erstellt am
  cycle_of_document: Dokumentzyklus
  delete: Dokument löschen
  delete_confirmation_message: >-
    Sind Sie sicher, dass Sie das Dokument löschen möchten? Diese Aktion ist
    nicht rückgängig zu machen.
  device: Ladestation
  display: Anzeige
  display_parameters: Anzeigeparameter
  document_archive_message: Das Dokument wurde erfolgreich archiviert
  document_archive_title: Dokument archiviert
  document_creation_message: Das Dokument wurde erfolgreich erstellt
  document_delete_message: Das Dokument wurde erfolgreich gelöscht
  document_to_model_success: Dokument erfolgreich in Modell umgewandelt
  document_update_error_message: Beim Speichern des Dokuments ist ein Fehler aufgetreten
  document_duplicate_error_message: Beim Duplizieren des Dokuments ist ein Fehler aufgetreten
  document_update_message: Das Dokument wurde erfolgreich gespeichert
  enter: Eingeben
  exit_without_saving: Ohne Speichern verlassen
  expiration_date: Ablaufdatum
  file: Link
  file_extension_error: Die Erweiterung ist nicht erlaubt
  finish: Bearbeitung des Dokuments abschließen
  finish_confirmation_message: >-
    Sind Sie sicher, dass Sie die Eingabe auf diesem Datensatz abgeschlossen
    haben?
  format: Format
  frequency: Häufigkeit
  get_more: Laden + { count } Dokument | Laden + { count } Dokumente
  get_more_revisions: Laden + { count } Revision | Laden + { count } Revisionen
  input_frequency: Eingabehäufigkeit
  input_frequency_activate: Häufige Eingaben aktivieren
  last_updated_on: Zuletzt aktualisiert am
  lifecycle: Lebenszyklus
  lines: Zeilen
  load_more: Weitere Dokumente laden
  load_more_revisions: Mehr Revisionen laden
  measures: Maßnahme
  multiple_selections: Mehrfachauswahl
  name: Name
  new_document: Neues Dokument
  no_files: Es sind keine Dateien verfügbar
  no_workplace_documents: Keine Datensätze verfügbar für diese Arbeitsplatz
  non_valid: Ungültig
  not_found: Kein Datensatz gefunden
  note: Notiz
  notify_operator: Operator benachrichtigen
  number_of_columns: Anzahl der Spalten
  order: Reihenfolge
  production_order: Fertigungsauftrag
  publish_document: Dokument veröffentlichen
  display_column_one_by_one: Spalte für Spalte anzeigen
  schedule:
    as_soon_as: Ab dem
    as_soon_as_the_publication: Bei Veröffentlichung
    available_from: Verfügbar ab
    availablity: Verfügbarkeit
    day: Tag | Tage
    document_is_accessible: Das Dokument wird zugänglich sein
    end: Ende
    end_at: Enddatum
    every_day: Alle {nb} Tage
    every_f: Jede | Jeden
    every_m: Jeden
    every_month: Jeden {nb} Monat
    every_week: Alle {nb} Wochen
    every_week_article: Alle
    every_week_days: Jeden {days}
    every_with_article: Jeden
    every_year: Alle {nb} Jahre
    from_date: ab {date}
    hour: Stunde | Stunden
    minute: Minute | Minuten
    month: Monat | Monate
    no_end: Kein Enddatum
    no_schedule: Keine Wiederholung
    no_start: Kein Startdatum
    no_starting_date: Kein Startdatum
    not_recognized: Wiederholung nicht erkannt
    permanently_available: Dauerhaft verfügbar
    publication_date: Veröffentlichungsdatum
    recurrence: Wiederholung
    recurrence_from: Von
    recurrence_to: bis
    repeat: Wiederholungen
    repeat_each: Wiederholen alle
    repeat_each_feminine: Wiederholen alle
    set_hour: Stunde einstellen
    set_hour_error: Das Enddatum muss nach dem Startdatum liegen.
    specific_recurrence: Spezifische Wiederholung
    start: Start
    start_at: Startdatum
    the_f: die | die
    the_m: der | die
    until_date: bis {date}
    week: Woche | Wochen
    year: Jahr | Jahre
  scheduling: Planung
  scheduling_subtitle: Planen Sie die Anzeige des Dokuments im Betriebsumfeld
  search: Dokument suchen
  select_application_fields: Anwendungsfelder auswählen
  simulate: Simulieren
  start_date: Startdatum
  start_to_create_a_new_document: Beginnen Sie mit der Erstellung eines neuen Dokuments
  starting_at: Startet um
  starting_with: beginnt mit
  state: Zustand
  status: Status
  status_active: Aktiv
  status_archived: Archiviert
  status_deleted: Gelöscht
  status_draft: Entwurf
  status_finished: Abgeschlossen
  status_in_progress: In Bearbeitung
  status_in_review: In Überprüfung
  status_inactive: Inaktiv
  status_lot_libéré: Chargenfreigabe
  status_new: Neu
  status_being_processed: Verarbeitet wird
  status_resolved: Gelöst
  status_production_validée: Produktionsvalidiert
  status_published: Veröffentlicht
  status_qualified: Qualifiziert
  status_released: Freigegeben
  status_revu_quali_en_cours: Qualitätsprüfung läuft
  status_select: Auswählen
  step_check: OK/NOK
  step_checkbox: Kontrollkästchen
  step_file: Datei
  step_link: Link
  step_measure: Maßnahme
  step_number: Nummer
  step_calculator: Rechner
  step_options_list: Optionsliste
  step_photo: Foto
  step_photo_no_camera_access: >-
    Der Kamerazugriff ist deaktiviert. Bitte erlauben Sie den Zugriff in den
    Einstellungen Ihres Browsers.
  step_photo_no_devices_found: Keine Geräte gefunden. Bitte schließen Sie eine USB-Kamera an.
  step_photo_preview_picture: Foto-Vorschau
  step_photo_take_picture: Foto aufnehmen
  step_photo_add_picture: Ein Foto hinzufügen
  step_photo_take_picture_again: Foto erneut aufnehmen
  step_photo_take_picture_description: >-
    Verwenden Sie Ihre Kamera, um ein Foto aufzunehmen. Klicken Sie auf
    "Aufnehmen", um das Foto zu speichern, und sehen Sie es sich 2 Sekunden lang
    an, bevor das Modalfenster automatisch geschlossen wird.
  step_photo_update_picture: Foto bearbeiten
  step_photo_update_picture_description: >-
    Sie können Ihr vorhandenes Foto aktualisieren, indem Sie die Kamera
    verwenden. Klicken Sie auf "Foto aufnehmen", um das neue Foto zu speichern.
    Es wird 2 Sekunden lang angezeigt, und das Popup-Fenster wird automatisch
    geschlossen, wodurch das alte Foto ersetzt wird.
  step_text: Text
  step_time: Zeit
  steps_linked_to_template: Blöcke sind bereits mit einem Modell verknüpft
  title: Meine Einträge
  to_fill: Ausfüllen
  trigger: Auslöser
  trigger_element: Auslösendes Element
  type: Typ
  type_auto: Automatisch (NIR)
  type_manual: Manuell
  type_readonly: Nur lesen
  uncertain: Unsicher
  unlink_steps: Trennen Sie zuerst die bereits importierten Blöcke von einem Modell.
  unsaved_changes_description: Alle Änderungen gehen verloren, wenn Sie dieses Dokument nicht speichern
  unsaved_changes_title: Dieses Dokument ist nicht gespeichert
  update_research: Suche aktualisieren
  updated_at: Aktualisiert am
  updated_by: Aktualisiert von
  updated_on: Aktualisiert am
  use_as_template: Als Vorlage verwenden
  valid: Gültig
  version: Version
  version_reason_add: Eine Notiz zur neuen Version des Dokuments hinzufügen
  version_reason_error: Sie müssen die neue Version des Dokuments rechtfertigen.
  workflow: Lebenszyklus
  you_have_completed: Sie haben abgeschlossen
error:
  email_already_in_use: Diese E-Mail-Adresse wird bereits verwendet
  nfc_code_policy_not_valid: Der Code muss 16 Zeichen lang sein
  not_same_old_password: Das neue Passwort darf nicht mit dem alten Passwort übereinstimmen
  password_policy_not_valid: >-
    Das Passwort muss mindestens 8 Zeichen lang sein und sowohl Klein- als auch
    Großbuchstaben enthalten
  passwords_not_match: Die Passwörter stimmen nicht überein
export:
  at: um
  attachments_of_document: Anhänge des Dokuments
  change_target: Zieländerung
  columns_count: Anzahl der Spalten
  comment: Kommentar
  created_at: Erstellt am
  captured_data: Erfasste Daten zwischen
  created_by: Erstellt von
  description: Beschreibung
  document_export_error: Ein Fehler ist bei der Vorbereitung Ihres Dokuments aufgetreten.
  download_error: Ein Fehler ist während der Verbindung zum Server aufgetreten.
  download_is_starting: Ihr Download beginnt in Kürze...
  export_document_as_pdf: Dokument als PDF herunterladen
  export_type: Exporttyp
  goal: Ziel
  historic_value: Historischer Wert
  justification: Begründung
  max: Höchstwert
  min: Mindestwert
  modified_at: Geändert am
  modified_by: Geändert von
  name: Name
  operator: Operator
  report_title: Titel des Berichts
  status: Status
  step_type: Schritttyp
  tags: Tags
  tool: Messgerät
  type: Typ
  unit: Einheit
  update_date: Zuletzt aktualisiert am
  value: Wert
  workplaces: Arbeitsplätze
filters:
  activities: Aktivitäten
  add: Berichte filtern
  alternative_workplaces: Alternative Arbeitsplätze
  assignation: Zuweisung
  model: Vorfälle bilden sich
  incident_form: Vorfälle bilden sich
  assignee: Beauftragter
  at_least_one_display_column: Mindestens eine Option muss ausgewählt werden
  blocker: Blockierend
  blockers: Blockierend
  categories: Dokumentkategorie
  choose_filter: Filter auswählen
  date: Datum
  display: Anzeige
  display_columns: Angezeigte Spalten...
  documents: Dokument
  filter: Filtern
  filter_by: Filtern nach
  go_back: Zurück zu den Filteroptionen
  group_by: Gruppieren nach
  last_14_days: Letzte 14 Tage
  last_24_hours: Letzte 24 Stunden
  last_3_days: Letzte 3 Tage
  last_7_days: Letzte 7 Tage
  last_month: Letzter Monat
  machines: Ladestationen
  models: Vorlagen
  no_grouping: Keine
  no_product: Kein Produkt
  no_workplace: Kein Arbeitsplatz
  operation: Operation
  operations: Operationen
  parent: Elternteil
  product: Produkt
  production_orders: Fertigungsaufträge
  products: Produkte
  raised_by: Eingereicht von
  remove_all: Alles entfernen
  role: Benutzerrolle
  search: Filter suchen...
  select_a_date: Datum auswählen
  site: Standort
  status: Status
  teams: Teams
  templates: Vorlagen
  title: Filter
  today: Heute
  users: Benutzer
  work_order: Arbeitsauftrag
  workplace: Arbeitsplatz
  workplaces: Arbeitsplätze
  yesterday: Gestern
firebase:
  too_many_attempts: >-
    Ihr Konto ist vorübergehend deaktiviert, da Sie zu viele Versuche
    unternommen haben. Bitte setzen Sie Ihr Passwort zurück.
  unexpected_error: Ein unerwarteter Fehler ist aufgetreten. Bitte aktualisieren Sie die Seite.
  user_disabled: Ihr Konto wurde deaktiviert
  weak_password: Das Passwort muss mindestens 6 Zeichen lang sein
  wrong_actual_password: Falsches aktuelles Passwort
  wrong_email: Falsche E-Mail-Adresse
  wrong_password: Falsches Passwort
format:
  fixed: Fest
  formula: Formel
  quantity: Menge
  unique: Einzigartig
  unlimited: Unbegrenzt
global:
  automatic_machine: Automatische Maschinen
  validation_error: "Fehler beim Importieren Ihrer Datei, bitte überprüfen Sie Ihre Daten"
  updated_by: Aktualisiert von
  modify: Ändern
  display_NC_only: Nur Spalten mit Nichtkonformitäten anzeigen
  step: Schritt
  steps: Schritte
  cell: Zelle
  history_annex: historischer Anbau
  pictures_annex: Anhang mit Bildblöcken
  document_attachment_annex: Anhang der dem Bericht beigefügten Dokumente
  step_attachment_annex: Anhang der den Stufen beigefügten Dokumente
  column: Spalte | Spalten
  account_and_access: Benutzer und Zugriff
  actions: Aktionen
  add: Hinzufügen
  add_a_attachment_file: Datei anhängen
  add_user: Benutzer hinzufügen
  additional_informations: Zusätzliche Informationen
  alert: Warnung | Warnungen
  next: nächste
  alerts: Warnungen
  alerts_success: Warnung erfolgreich erstellt!
  selected_production_orders_from_to: Fertigungsaufträge {from}–{to} von insgesamt {total} Fertigungsaufträgen
  selected_production_orders: '{n} ausgewählten Bericht | {n} ausgewählte Berichte'
  all: Alle
  allTeams: Alle Teams
  all_the_documents: Alle Dokumente
  all_the_of: Alle Auftragsfreigaben
  all_the_templates: Alle Vorlagen
  analyses: Analysen
  and: und
  application_fields: Anwendungsfelder
  archive: Archivieren
  are_you_sure_you_want_to_delete_user: Sind Sie sicher, dass Sie diesen Benutzer löschen möchten
  are_you_sure_you_want_to_logout: Sind Sie sicher, dass Sie sich abmelden möchten
  at: um
  attachment: Anhang
  attachments: Anhänge
  attention_any_unsaved_document_will_be_lost: Achtung! Nicht gespeicherte Dokumente gehen verloren!
  audit_logs: Verbindungsprotokolle
  back_home: Zurück zur Startseite
  beforeOrAfter: Vor oder nach
  both_passwords_dont_match: Die beiden Passwörter stimmen nicht überein
  bread_crumb:
    dokumente: Dokumente
    vorlagen: Vorlagen
    simulation: Simulation
  cancel: Abbrechen
  change_language_to_apply: >-
    Es wird dringend empfohlen, die Seite neu zu laden, um die Änderungen
    anzuwenden
  change_password: Passwort ändern
  choice: Wahl
  click_button_reload: Klicken Sie auf "Neu laden", um darauf zuzugreifen.
  client: Kunde
  clients: Kunden
  close: Schließen
  complete: Abschließen
  confirm: Bestätigen
  confirm_import: Import bestätigen
  confirm_import_desc: >-
    Durch das Importieren dieser Datei werden die zuvor in JUNO importierten
    Kundeninformationen aktualisiert
  confirm_modification: Änderungen bestätigen?
  confirm_password: Passwort bestätigen
  confirm_password_validation_label: Beide Passwörter
  confirmation_password: Bestätigungspasswort
  content: Inhalt
  copy_of: Kopie von
  create: Erstellen
  created_at: Erstellt am
  created_by: Erstellt von
  created_on: Erstellt am
  current_language: fr
  current_password: Aktuelles Passwort
  dashboard: Dashboard
  days: Tage
  define_as_a_template: Als Vorlage definieren
  delete: Löschen
  description: Beschreibung
  disconnected: Sie wurden abgemeldet
  disconnected_for_inactivity: Sie wurden aufgrund von längerer Inaktivität abgemeldet
  display: Anzeigen
  document: Dokument | Dokument | Dokumente
  document_parameters: Konzeption
  documents: Dokumente
  download: Herunterladen
  download_as_pdf: Als PDF herunterladen
  duplicate: Duplizieren
  duplicate_on_destination_site: Zielseite
  duplicate_no_language_selected: Keine Sprache ausgewählt
  duplicate_no_site_selected: Keine Seite ausgewählt
  duplicate_and_translate: Dokument übersetzen
  duplicate_on_another_site: Auf einer anderen Seite duplizieren
  duplicate_on_another_site_successful: Das Dokument wurde erfolgreich auf der Zielseite dupliziert
  edit: Bearbeiten
  element: Kein Element | 1 Element | {n} Elemente
  element_not_found: Kein Element gefunden
  email: E-Mail
  empty_list: Kein Eintrag gefunden
  enriched: Angereichert
  enriched_export: Angereicherter Export
  error: Fehler
  export: Exportieren
  files: Postendateien
  filter: Filter | Filter
  finish_document: Dokument abschließen
  finish_entry: Meine Eingabe beenden
  finished: Fertiggestellt
  first_name: Vorname
  for: Für
  forbidden: Zugriff auf diese Seite verboten
  forbidden_message: Wenden Sie sich an Ihren Administrator, um den Zugriff zu überprüfen
  force_reset_password: Sie melden sich zum ersten Mal an, bitte setzen Sie Ihr Passwort zurück
  from: ab
  ftp_parameters: FTP
  group: Gruppe | Gruppen
  hello: Hallo
  history: Verlauf
  id: ID
  if: Wenn
  import: Importieren
  import_export_parameters: Import/Export
  inProgress: In Bearbeitung
  in_progress: In Bearbeitung
  instructions: Anweisungen
  integration_parameters: Integration
  integrations: Integrationen
  justification: Begründung
  label: Bezeichnung
  last_modification: Letzte Änderung
  last_name: Nachname
  library: Bibliothek
  link: Link
  list: Liste
  loading: Laden...
  login: Anmelden
  logout: Abmelden
  mandatory: Obligatorisch
  mark_as_read: Alles als gelesen markieren
  media_library: Mediathek
  minutes: Minuten
  modeles: Vorlagen
  monitoring: Überwachung
  month: Monat
  my_dashboards: Meine Dashboards
  name: Name
  name_document: Dokumentenname
  nb_of_boxes: Anzahl der Kästen
  need_reset_password: Müssen Sie Ihr Passwort zurücksetzen?
  network_error: Keine Internetverbindung
  new: Neu
  new_password: Neues Passwort
  new_version: Eine neue Version von Juno ist verfügbar
  next_check_at: Prüfung auf Blatt {reportName}, auf Schritt {step} durchführen
  'no': Nein
  no_value: Kein Wert
  notification_parameters: Benachrichtigungen
  number_of_steps: '{number} étape | {number} étapes'
  ok: OK
  operation: Operation
  operator_interface: Bedieneroberfläche
  optional: Optional
  or: Oder
  other: Andere
  out_of: von
  parameters: Parameter
  parent: Übergeordnet
  password: Passwort
  password_expired: Ihr Passwort ist abgelaufen, bitte setzen Sie es zurück
  password_has_changed: Ihr Passwort wurde geändert
  password_update_error_message: Fehler beim Ändern des Passworts aufgetreten
  people: '{amount} Personen'
  per_page: pro Seite
  percentage: Prozent
  performances: Leistungen
  permissions: Rollen und Berechtigungen
  picture: Bild
  please_select_type: Bitte wählen Sie einen Typ aus
  preview: Vorschau
  product: Produkt
  publish: Veröffentlichen
  range: Bereich
  reason: Grund
  refresh: Aktualisieren
  reload: Neu laden
  remove_attachment: Anhang entfernen
  report: Bericht | Berichte
  reports: Berichte
  reset_email_sent: >-
    Eine E-Mail zum Zurücksetzen Ihres Passworts wurde gesendet. Klicken Sie auf
    den Link, wenn Sie ihn erhalten haben.
  resolved: Gelöst
  restore: Wiederherstellen
  revision: Revisionen
  role: Rolle
  roles: Rollen
  row: Reihe | Reihen
  save: Speichern
  save_preferences: Einstellungen speichern
  search: Suchen
  search_assignee: Zuweisen an
  search_means_of_measurement: Suchen...
  search_unit: Einheit suchen...
  seeAll: Alle anzeigen
  seeDescription: Beschreibung anzeigen
  select: Auswählen
  selectTeam: Wählen Sie ein Team aus
  select_tab: Wählen Sie einen Tab aus
  select_type: Typ auswählen
  selected_documents: '{n} ausgewähltes Dokument | {n} ausgewählte Dokumente'
  selected_documents_from_to: '{from}-{to} von insgesamt {total} Dokumenten ausgewählt'
  selected_lookups_from_to: '{from}-{to} von insgesamt {total} Datensätzen ausgewählt'
  selected_products_from_to: '{from}-{to} von insgesamt {total} Produkten ausgewählt'
  selected_workplaces_from_to: '{from}-{to} von insgesamt {total} Arbeitsplätzen ausgewählt'
  send: Senden
  send_reset_email: Passwort-Zurücksetzungs-E-Mail senden
  settings: Einstellungen
  sign_out: Abmelden
  simple_export: Einfacher Export
  simulation: Simulation
  site_management: Standortverwaltung
  sites: Standorte
  complementary_sites: Ergänzende Standorte
  start: Start
  startTask: Aufgabe starten
  status_management: Statusverwaltung
  steps_parameters: Schrittparameter
  success: Erfolg
  summary: Zusammenfassung
  switch_to_operator: Produktionsfläche
  switch_to_supervisor: Raumüberwachung
  switch_user: Benutzer wechseln
  switch_user_description: >-
    Sie sind dabei, den Benutzeraccount zu wechseln. Nach Bestätigung werden Sie
    zur Anmeldeseite weitergeleitet und müssen sich mit den Anmeldedaten eines
    anderen Benutzers anmelden, um zu dieser Seite zurückzukehren.
  switch_user_title: Benutzer wechseln
  tags: Tags
  template: Vorlage
  template_messages_parameters: Vorlagenparameter für Nachrichten
  templates: Vorlagen
  then: Dann
  time: Zeit
  title: Titel
  to_consult: Zu konsultieren
  today: Heute
  total_of_controles: Gesamtanzahl der Kontrollen
  total_parts: Gesamtteile
  total_report: Gesamtberichte
  understood: Verstanden
  unknown: Unbekannt
  unlink_step: Möchten Sie diesen Schritt wirklich von seiner Vorlage trennen?
  unlink_step_message: >-
    Die Bearbeitung dieses Schritts bedeutet, dass er nicht mehr aktualisiert
    werden kann, wenn Sie die Verbindung mit der folgenden Vorlage trennen:
  update: Aktualisieren
  user: Benutzer
  user_session_management: Benutzersitzungsverwaltung
  users: Benutzer
  validate: Überprüfen
  view: Anzeigen
  warning: Warnung
  workflow_parameters: Lebenszyklus
  workflows: Dokumenten-Workflows
  workplace: Arbeitsplatz
  'yes': Ja
  you_are_offline: Sie sind derzeit offline. Bitte melden Sie sich erneut an.
  your_profile: Ihr Profil
  ask_site_admin: Bitte kontaktieren Sie den Administrator dieser Seite
  no_rights: Sie haben kein Recht
global_fields:
  created_by: erstellt von
  created_on: erstellt am
groups:
  created_at: Erstellungsdatum
  load_more_groups: Mehr Gruppen laden
  name: Name
  no_result: Kein Ergebnis für Ihre Suche gefunden.
  placeholder: Personen oder Gruppen auswählen, die benachrichtigt werden sollen
  users: Benutzer
history:
  and_more: und {amount} mehr...
  assigned: hat das Problem zugewiesen an
  commented: hat kommentiert
  created: hat das Problem gemeldet
  history: Historie
  updated: hat den Status des Problems geändert
  started: die Bitte um Hilfe lautet im Staat
insights:
  valid_blocks: Gültige Blöcke
  valid_control_points: Gültige Kontrollpunkte
  valid_controls: Gültige Kontrollen
incidents:
  resolved_automatically: Dieser Vorfall wurde mithilfe des Formulars automatisch gelöst.
  raised_incident: hat eine neue Supportanfrage gestellt.
  collected_answers: Antworten gesammelt
  unsaved_changes_title: Diese Formel wird nicht gespeichert
  unsaved_changes_description: Wenn Sie dieses Formular nicht speichern, gehen alle Änderungen verloren
  add_question: Schrittname...
  add_answer: Name der Antwortauswahl...
  help_request: Hilfeanforderung
  add_comment: Kommentieren Sie oder geben Sie weitere Details an...
  add_comment_on_incident: Bitte fügen Sie einen Kommentar hinzu, um uns zu helfen, im Detail zu verstehen...
  incidents_form: Formular „Vorfälle“
  last_question: Hat Ihnen dieses Formular bei der Lösung Ihres Problems geholfen?
  help_form_sent_title: Erfolgreich gesendet
  help_form_sent_content: Ihre Supportanfrage wurde erfolgreich gesendet.
  no_model_available: Derzeit sind keine Supportmodelle verfügbar.
  modify_data_error: Einige erforderliche Daten wurden nicht korrekt ausgefüllt.
  modify_data_success: Das neue Formular wurde erfolgreich gespeichert.
  missing_response: Verpasste Antwort
instructions:
  incidents: Supportanfragen
  archive: Archivieren
  create_error: Ein Fehler ist aufgetreten
  create_error_msg: Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut
  created_successfully: Anweisung(en) erfolgreich erstellt
  export: Exportieren
  instructions_transfer: Übergabe von Anweisungen
  modify_instructions: Ändern
  new_instruction: Neue Anweisung
  no_instructions_found: Keine Anweisungen gefunden
  save_instruction: Anweisung speichern
  selected: ausgewählt | ausgewählt
  unarchive: Entarchivieren
  update_error: Ein Fehler ist aufgetreten
  update_error_msg: Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut
  updated_successfully: Anweisung(en) erfolgreich aktualisiert
  write_instructions: Schreiben Sie Ihre Anweisungen für den nächsten Bediener...
intro:
  aka: Auch bekannt als
  desc: Schnellstartmodell von Vite Opinionated
  dynamic-route: Dynamische Routendemo
  hi: "Hallo, {name}\_!"
  whats-your-name: Wie ist dein Name?
jobs:
  choose_workplace: Wählen Sie Ihren Arbeitsplatz aus
  choose_workplace_desc: Wählen Sie Ihren Arbeitsplatz aus, um Fertigungsaufträge anzuzeigen
  create_production_order: Diesen Fertigungsauftrag erstellen
  current_jobs: Arbeitsplatzübersicht
  last_10_days: Fertigungsaufträge der letzten 10 Tage
  last_30_days: Fertigungsaufträge der letzten 30 Tage
  no-result: Kein Ergebnis für diese Suche
  no_product_order_selected: >-
    Kein Fertigungsauftrag gefunden! Klicken Sie hier, um ein Produkt
    auszuwählen
  not_found: Keine offenen Fertigungsaufträge gefunden
  number: Auftragsnummer
  operation: Operationsnummer
  production_order_doesnt_exist: Dieser Fertigungsauftrag existiert nicht!
  select-of: Bitte wählen Sie eine Auftragsnummer aus
  select_product: Bitte wählen Sie ein Produkt aus
  start_new: Neuen Fertigungsauftrag starten
  start_new_job_desc_no_jobs: >-
    Öffnen Sie einen Fertigungsauftrag, indem Sie oben rechts auf dem Bildschirm
    auf die Schaltfläche klicken
  status_finished: Abgeschlossen
  status_finished_message: Der Fertigungsauftrag wurde abgeschlossen
  status_in_progress: In Bearbeitung
  status_new: Neu
languages:
  de: Deutsch
  en: Englisch
  english: Englisch
  es: Spanisch
  fr: Französisch
  french: Französisch
  german: Deutsch
  it: Italienisch
  italian: Italienisch
  spanish: Spanisch
media_library:
  add: Datei importieren
  archive_confirmation_message: Sind Sie sicher, dass Sie diese Datei archivieren möchten?
  delete: Datei archivieren
  delete_confirmation_message: Sind Sie sicher, dass Sie diese Datei löschen möchten?
  failed_upload: Einige Dateien konnten aufgrund eines Fehlers nicht hochgeladen werden
  file_update_error_message: >-
    Beim Verbinden mit dem Server ist ein Fehler aufgetreten. Bitte versuchen
    Sie es erneut.
  media_parameters: Medienbibliothek
  no_data: >-
    Es wurden keine Dateien importiert. Klicken Sie auf die Import-Schaltfläche,
    um Ihre Dateien hinzuzufügen.
  no_file_yet: Fügen Sie hier Ihre Dateien hinzu, um sie in JUNO zu importieren.
  no_search_result: Es wurden keine Dateien gefunden, die Ihrer Suche entsprechen.
  no_archived_files: Keine archivierten Dateien
  search_file: Durchsuchen Sie Ihren Computer nach Dateien
  show: Aktiviert
  staged_file: Ausgewählte Datei
  staged_files: 1 Datei ausgewählt | %{count} Dateien ausgewählt
  tab_archived: Archiviert
  tab_files: Verfügbare Dateien
  update_file: Datei ersetzen
  upload: Hochladen
  upload_files: Dateien hochladen
  uploaded_files: 1 Datei erfolgreich hochgeladen | %{count} Dateien erfolgreich hochgeladen
mes:
  top_5_products: Top 5 der Produkte
  flop_5_products: Flop 5 der Produkte
  charts:
    synthetic_rate_of_return: Syntheseertragsrate
    availability_rate: Verfügbarkeitsrate
    quality_rate: Qualitätsrate
    performance_rate: Leistungsrate
    percentage_of_use: '% Nutzung'
    stop_pareto: Pareto der Stopps
    scrap_pareto: Pareto der Ausschüsse
    slowdown_pareto: Pareto der Verlangsamungen
  my_performance: Meine Leistung
  scrap: Ausschuss | Ausschüsse
  signal_scrap: Einen Ausschuss melden
  scrap_quantity: Ausschussmenge
  production_rate_expected: Erwartete Rate
  add_production_rate_expected: Erwartete Rate hinzufügen
  number_of_pieces: Anzahl der
  show_nc_only: Nur die Nichtkonformitäten
  no-nc-today: Heute gibt es keine Nichtkonformitäten
  no-nc-in-selected-date: Am ausgewählten Tag liegt keine Nichtkonformität vor.
  machine_status_between: Der Zustand der Ladestation zwischen %{start} und %{end}
  pieces: Stücke
  associate-po-to-my-machine:
    description: >-
      Geben Sie die erforderlichen Informationen ein, um einen Fertigungsauftrag
      mit Ihrer Ladestation zu verknüpfen und die vorgesehene Produktionsmenge zu
      sehen.
    production-order-number-label: Fertigungsauftragsnummer
    production-order-number-placeholder: Fertigungsauftragsnummer eingeben...
    quantity-label: Zu produzierende Menge
    quantity-placeholder: Zu produzierende Menge eingeben...
    save: Verknüpfen
    title: Fertigungsauftrag mit meiner Ladestation verknüpfen
  handle_production:
    start_on_current_session: Die Produktion auf der Ladestation beginnen
    confirm_current_production: Bestätigen Sie, dass der aktuelle Auftrag in Produktion auf der Ladestation ist
    confirm_finished_production: Bestätigen Sie, dass der aktuelle Auftrag nicht mehr in Produktion auf der Ladestation ist
    unlink_env: 'Ihre Produktion wird von der folgenden Arbeitsumgebung getrennt:'
    link_env: 'Ihre Produktion wird mit der folgenden Arbeitsumgebung verbunden:'
    relink_env: 'und wird mit dieser Arbeitsumgebung wieder verbunden:'
    start: Meine Produktion starten
    in_progress: Produktion läuft
    stop: Meine Produktion stoppen
  machines: Ladestationen
  compared_to: Verglichen mit
  date: Datum
  available: Verfügbar
  batch: Charge
  digram-trs: TRS-Kaskadendiagramm
  machine: Ladestation
  machine-has-no-data: Die Ladestation hat keine Daten
  machines-indicators: Indikatoren nach Ladestation
  net-production-time: Nettoproduktionszeit
  no-machines-found: Keine Ladestationen gefunden
  opened-time: Öffnungszeit
  paretor-unknown-stoppage: Pareto der unbekannten Stillstände
  performances: Leistungen
  oee: OEE
  availability: Verfügbarkeit
  performance: Leistung
  quality: Qualität
  po: Fertigungsauftrag
  production: Produktion
  production-indicators: Produktionsindikatoren
  production-monitoring: Produktionsüberwachung
  production-rate: Produktionsrate
  production-rate-delta: Produktionsratenabweichung
  production-time: Produktionszeit
  quality-waste: Ausschuss
  production_target_line_chart: Produktionsziel
  setting: Einstellung
  setting-shutdown-reasons: Einstellung der Abschaltgründe
  all-machines: Alle Ladestationen
  settings:
    modal:
      color: Farbe der Einstellung
      edit:
        title: Abschaltgrund bearbeiten
      machine: Ladestation
      new:
        title: Neuer Abschaltgrund
      reason: Titel des Abschaltgrundes
      reason-placeholder: Titel eingeben...
      stop-machine-reason: Ladestation auswählen
    table:
      color: Farbe
      machine: Ladestation
      status: Status
      title: Titel
      produced-parts: Produzierte Teile
      oee: OEE (%)
      availability: Verfügbarkeit (%)
      performance: Leistung (%)
      quality: Qualität (%)
  total-time: Gesamtzeit
  unknown-stoppage: Unqualifizierter Stillstand
  no-available-data: Keine verfügbaren Daten
  full-screen: Vollbildmodus
  exit-full-screen-mode: Vollbildmodus beenden
  root_causes:
    title: Meine Grundursachen
    demo:
      rc_1_title: Schlechte Maschineneinstellung
      rc_2_title: Formatwechsel
      rc_3_title: Unzureichende Maschinenreinigung
      rc_4_title: Komponenteninkompatibilität
      rc_5_title: "Lieferfehler"
      rc_children_1_title: Kalibrierungsfehler
      rc_children_2_title: Falsch eingestellte Produktionsgeschwindigkeit
months:
  april: April
  august: August
  december: Dezember
  february: Februar
  january: Januar
  july: Juli
  june: Juni
  march: März
  may: Mai
  november: November
  october: Oktober
  september: September
not-found: Nicht gefunden
notifications:
  activate_feature: Aktivieren oder deaktivieren
  alert_type: Alarmbenachrichtigungen
  controls_needed: Kontrolle erforderlich | Kontrollen erforderlich
  go_to_task: Zur Aufgabe gehen
  machine_stopped: >-
    Ladestation um %{time} gestoppt. Bitte geben Sie sofort den Grund für diesen
    Stopp an.
  qualify_stop: Stopp qualifizieren
  reminder_type: Erinnerungsbenachrichtigungen
  undefined_document: Unbenanntes Dokument
  view: Benachrichtigung anzeigen
operator:
  all_workplaces: Alle Arbeitsplätze
  all_workplaces_page: Alle Ladestationen
  filter_by_workplaces: Nach Arbeitsplätzen filtern
  no_workplaces_recently_opened: Sie haben noch keinen Arbeitsplatz geöffnet
  qualify:
    modal:
      comment: Kommentar
      comment-placeholder: Kommentar hinzufügen...
      description: >-
        Ein Produktionsstillstand wurde um %{time} auf der Ladestation %{machine}
        festgestellt. Geben Sie die folgenden Details an, um das
        Stillstandereignis zu qualifizieren.
      optional: Optional
      reason: Stillstandsgrund
      reason-placeholder: Stillstandsgrund auswählen...
      title: Maschinenstillstand qualifizieren
  requalify:
    modal:
      description: >-
        Geben Sie die folgenden Informationen an, um dieses Stillstandereignis
        neu zu qualifizieren.
      reason: Grund für die Neubewertung
      reason-placeholder: Grund für die Neubewertung auswählen...
      title: Maschinenstillstand neu qualifizieren
  search_workplace: Arbeitsplatz suchen
  search_workplace_message: Verfeinern Sie Ihre Suche, um die zugehörigen Arbeitsplätze anzuzeigen
  sel: Arbeitsplatz suchen
  select_all_workplaces: Alle Arbeitsplätze auswählen
  sidebar:
    machine-is-available-again: Ladestation ist wieder verfügbar
    machine-is-off: Ladestation ist aus
    machine-monitoring: Maschinenüberwachung
    nir: NIR-Überwachung
    qualify: Qualifizieren
    requalify: Neu qualifizieren
    workplace-documents: Arbeitsplatzdokumente
  sidebar_nir_monitoring: NIR-Überwachung
  sidebar_operations_support: Betrieblicher Support
  sidebar_workplace_documents: Arbeitsplatzdokumente
  workplace: Arbeitsplatz
  workplace_doesnt_exist: Dieser Arbeitsplatz existiert nicht
  workplaces_recently_opened: Zuletzt geöffnete Arbeitsplätze
pages:
  alerts: Alle Warnmeldungen
  alerts_list: Alle Warnmeldungen
  analytics: Analysen
  document: Dokumente
  form: Formulare
  history: Verlauf
  library: Bibliothek
  media_library: Medienbibliothek
  monitoring: Überwachung
  reports: Alle Berichte
  settings: Einstellungen
  template: Vorlagen
  views: Ansichten
profil:
  description: Verwalten Sie alle persönlichen Informationen in Ihrem JUNO-Profil
  email: E-Mail-Adresse
  language: Sprache
  my-profile: Mein Profil
  not_specified: Nicht spezifiziert
  security: Sicherheit
  trigram: Werkskennung
repetition:
  add: Wiederholung hinzufügen
  additional_filter_nature: Natur
  create_formula: Formel erstellen
  create_sampling: Stichprobe erstellen
  create_frequency: eine Frequenz erstellen
  created_successfully: Wiederholung erfolgreich erstellt
  created_successfully_message: Sie können nun die neue Wiederholung in Ihren Dokumenten verwenden
  creation_error: Ein unerwarteter Fehler ist aufgetreten
  creation_error_message: Ein unerwarteter Fehler ist beim Erstellen Ihrer Wiederholung aufgetreten.
  frequency_list: Frequenzliste
  event: Ereignis
  formula: Formel
  formula_type: Formeltyp angeben
  frequency: Häufigkeit
  frequency_selection_validation_error: Du solltest mindestens einmal auswählen.
  load_more: Mehr laden
  no_repetition_available: Keine Wiederholung verfügbar
  notification: Benachrichtigung
  repeat_every: Wiederholen alle
  repetition_exists_message: Diese Wiederholung existiert bereits
  repetitions: Wiederholungen
  reset: Zurücksetzen
  sampling: Stichprobe
  save: Speichern
  search: Suche
  select: Wählen Sie eine Wiederholung aus
  select_formula: Formel auswählen
  times: Mal
report:
  double_check_toast: Ein anderer Operator muss die Überprüfung durchführen
  checking_data: Überprüfung der Eingabe
  rechecking_data: Erneute Überprüfung der Eingabe
  checking_data_desc: Überprüfen Sie den Wert und bestätigen Sie ihn mit ok, oder verwerfen Sie ihn mit nok, wenn er falsch ist.
  go_to: Gehe zu
  enter_column: Eine Spalte eingeben...
  nc_columns: Nicht konforme Spalten
  more: weitere
  out_of: von
  columns: Spalten
  all_day: Den ganzen Tag
  category: Kategorie
  cell_history: Zellenverlauf
  cell_history_desc: Dieses Feld wurde von einem oder mehreren Benutzern geändert
  checkbox: Zu erledigen
  control: Kontrolle
  controls: Kontrollen
  custom_valid_controls: '{header} gültig'
  date: Datum
  document: Dokument
  done: Erledigt
  download_failed: Fehler
  download_failed_message: Ein Fehler ist beim Vorbereiten Ihres Dokuments aufgetreten.
  download_finished: Download läuft
  download_finished_message: Sie erhalten in wenigen Minuten eine E-Mail mit der Datei
  download_limit_reached: Download auf 45 Berichte begrenzt
  downloading: Herunterladen läuft...
  downloading_message: Bitte warten Sie, wir bereiten Ihr Dokument für den Download vor!
  dynamic: Dynamisch
  dynamic_target: Dynamisches Ziel
  enter_value: Wert eingeben...
  execution_date: Ausführungsdatum
  filter_invalid: Ungültig
  filter_last_3_days: Letzte 3 Tage
  filter_last_7_days: Letzte 7 Tage
  filter_last_day: Letzter Tag
  filter_last_month: Letzter Monat
  filter_valid: Gültig
  is_valid: Zustand
  job: Fertigungsauftrag
  job_abbreviation: FA
  justification: Begründung
  justify_ko_desc: >-
    Bitte begründen Sie den Wert der folgenden Zelle, um mit der Eingabe auf
    diesem Dokument fortzufahren.
  justify_ko_title: Begründung der Nichtkonformität
  last_update: Zuletzt aktualisiert am
  limit_reached: Sie haben Ihr Limit erreicht. Bitte verfeinern Sie Ihre Suche.
  measure: Maßnahme
  modify_data: Ändern einer zuvor erfassten Eingabe
  modify_data_desc: >-
    Die Daten dieser Zelle wurden von einem anderen Benutzer erfasst. Diese
    neuen Daten werden auf den Rastern jedes Benutzers auf dem Dokument
    aktualisiert.
  modify_data_error: Nicht alle Felder wurden korrekt ausgefüllt
  modify_data_success: Die neue Daten wurden erfolgreich gespeichert
  new_data: Neue Daten
  no_rows_to_show: Anzeigeproblem bei den Daten
  no_steps: Diese Datei ist leer
  operator: Betreiber
  precise_reasons: Geben Sie die Gründe für diese Änderung genau an...
  product: Produkt
  raise_event: Ereignis melden
  report_update_message: Das Dokument wurde erfolgreich gespeichert
  restore_data: Eingabe wiederherstellen
  restore_data_desc: >-
    Diese Daten wurden zuvor erfasst, bevor sie durch neuere Eingaben ersetzt
    wurden.
  restore_data_title: Möchten Sie diese Daten wirklich wiederherstellen?
  see_alert: Alarm anzeigen
  select_option: Option(en) auswählen
  static: Statisch
  status: Status
  status_finished: Abgeschlossen
  status_history: Statusverlauf
  status_in_progress: In Bearbeitung
  status_new: Neu
  step_answer: Antwort
  step_answer_state: Zustand
  step_name: Name
  step_number: Schritt
  target: Ziel
  text: Text
  update_date: Aktualisierungsdatum
  valid_controls: Gültige Kontrollen
  view_report: Bericht anzeigen
  work_order: Arbeitsauftrag
  workplace: Arbeitsplatz
session:
  all_documents_not_complete: Ihre Dokumente sind nicht vollständig
  all_documents_not_complete_desc: >-
    Sie können die Operation nicht abschließen, bis alle erforderlichen
    Dokumente fertiggestellt sind
  automatic_reports: Automatische Berichte
  create_operation: Neue Operation erstellen
  create_workorder: Neuen Fertigungsauftrag erstellen
  launch_on: Starten am
  of_name_requiered: Bitte geben Sie einen Namen für Ihren Fertigungsauftrag ein
  ongoing_session: Gestartete Sitzung
  ongoing_session_message: Sie sind dabei, eine bereits gestartete Sitzung fortzusetzen
  operation_requiered: Bitte erstellen Sie mindestens eine neue Operation
  product_requiered: Wählen Sie mindestens ein Produkt aus, um fortzufahren
  refine_search: Verfeinern Sie Ihre Suche, um zugehörige Fertigungsaufträge anzuzeigen
  search_po: Suche
  select_product: Produkt auswählen
  certified: Zertifiziert
  uncertified: Unzertifiziert
  to_certify: Zertifizieren Sie
  certification: Zertifizierung
  certification_updated_successfully: Erfolgreich verändertes Team
  add_team: Ein Team hinzufügen
  chain_manager: Manager
  select_chain_manager: Manager auswählen
  select_chain_manager_placeholder: Bitte wählen Sie den Manager
  select_team_members: Betreiber auswählen
  select_team_members_placeholder: Bitte wählen Sie Betreiber
  line_certification_verification: Überprüfung der Linienzertifizierung
  select_team_desc: Auswahl der Teammitglieder
  update: Aktualisieren
  no_attachments: Neue Anhänge werden hier erscheinen, sobald Sie sie hinzugefügt haben.
  workorder_doesnt_exist: Dieser Fertigungsauftrag existiert nicht.
settings:
  add_option: Geben Sie Ihre Option ein und drücken Sie die Eingabetaste
  additional_data: Zusätzliche Daten
  alerts: Benachrichtigungen
  alternative_workplaces: Alternative Arbeitsplätze
  audit_logs:
    action: Aktion
    action_hour: Aktionszeit
    deactivate: Deaktivierung
    get_more: Mehr laden + { count } Eintrag | Mehr laden + { count } Einträge
    load_more: Mehr Einträge laden
    login: Anmeldung
    logout: Abmeldung
    name: Zugehöriges Konto
    reactivate: Reaktivierung
  blocker: Blocker
  cancel: Abbrechen
  color: Farbe
  conception_rights: Gestaltungsrechte
  create_ftp_success_message: Der FTP-Server wurde erfolgreich registriert
  create_group: Gruppe erstellen
  create_lookup: Neue Daten erstellen
  create_product: Neues Produkt erstellen
  create_role: Rolle hinzufügen
  create_setting_success_message: Die Einstellung wurde erfolgreich erstellt
  create_status: Status erstellen
  create_workplace: Neuen Arbeitsplatz erstellen
  decimal_number: Anzahl der Dezimalstellen
  decimal_values: Dezimalwerte
  default: Standard
  delete: Einstellung löschen
  delete_confirmation_message: >-
    Sind Sie sicher, dass Sie die Einstellung löschen möchten? Diese Aktion ist
    nicht rückgängig zu machen.
  delete_lookup_description: Möchten Sie diese Daten wirklich archivieren?
  delete_lookup_title: Zelle archivieren
  delete_option: Option löschen
  delete_option_confirmation_message: >-
    Sind Sie sicher, dass Sie diese Option löschen möchten? Diese Aktion ist
    nicht rückgängig zu machen.
  delete_product_description: Möchten Sie dieses Produkt wirklich archivieren?
  delete_product_title: Produkt archivieren
  delete_setting_success_message: Die Einstellung wurde erfolgreich gelös
  delete_workplace_description: Möchten Sie diesen Arbeitsplatz wirklich archivieren?
  delete_workplace_title: Arbeitsplatz archivieren
  disabled: Deaktiviert
  document_category: Dokumentenkategorie
  document_status: Dokumentstatus
  enable_tolerance: Toleranz bei Messungen aktivieren
  enabled: Aktiviert
  ending_time: Endzeit
  exigence: Anforderung
  formula: Formel
  frequency: Häufigkeit
  get_more_document_category: Mehr laden + { count } Kategorie | Mehr laden + { count } Kategorien
  get_more_grid_header: Mehr laden + { count } Kopfzeile | Mehr laden + { count } Kopfzeilen
  get_more_list_options: Mehr laden + { count } Auswahlmenü | Mehr laden + { count } Auswahlmenüs
  get_more_means_of_measure: Mehr laden + { count } Messmethode | Mehr laden + { count } Messmethoden
  get_more_messages: Mehr laden + { count } Nachricht | Mehr laden + { count } Nachrichten
  get_more_step_tag: >-
    Mehr laden + { count } Schrittbezeichnung | Mehr laden + { count }
    Schrittbezeichnungen
  get_more_teams: Mehr laden + { count } Team | Mehr laden + { count } Teams
  get_more_units_of_measure: Mehr laden + { count } Maßeinheit | Mehr laden + { count } Maßeinheiten
  get_more_workflow_models: >-
    Mehr laden + { count } Workflow-Modell | Mehr laden + { count }
    Workflow-Modelle
  grid_header: Kopfzeile
  groups: Gruppen
  header_list: Reihenfolge
  input_modification: Eingabeänderung
  input_parameters: Eingaben
  is_selected: Ausgewählt
  justify_ko: NC-Begründung
  list_options: Auswahlmenü
  list_type: Listenname
  list_value: Optionenwert
  load_more_document_category: Mehr Kategorien laden
  load_more_grid_header: Mehr Kopfzeilen laden
  load_more_list_options: Mehr Auswahlmenüs laden
  load_more_means_of_measure: Mehr Messmethoden laden
  load_more_messages: Mehr Nachrichten laden
  load_more_step_tag: Mehr Schrittbezeichnungen laden
  load_more_teams: Mehr Teams laden
  load_more_units_of_measure: Mehr Einheiten laden
  load_more_workflow_models: Mehr Modelle laden
  lookup_exists: Daten bereits vorhanden, bitte ändern Sie eines der Felder.
  lookup_keys_error: >-
    Die Dateikonfiguration entspricht nicht den Schlüsseln dieses Typs. Bitte
    kontaktieren Sie Ihren Betriebsleiter.
  lookup_tables: Zuordnungstabellen
  mandatory_column: Dieses Feld ist obligatorisch
  mandatory_fields: Stellen Sie sicher, dass alle Pflichtfelder ausgefüllt sind
  mandatory_finish: Vollständige Eingabe
  maximum_inactivity_time: Maximale Inaktivitätszeit vor Abmeldung
  enable_incativity_logout: Abmelden nach Inaktivität
  means_of_measure: Messmethode
  measure_unit: Maßeinheit
  media_library: Aktiviert
  members: Mitglieder
  message: Inhalt
  mold: Form
  name: Name
  nature: Natur
  new_status: Unbenannter Status
  new_user: Neuer Benutzer
  no_user_found: Kein Benutzer gefunden
  number_of_days_of_validity: Anzahl der Tage Gültigkeit des aktuellen Passworts
  number_of_decimals: Anzahl der Dezimalstellen
  number_of_columns_per_page: Anzahl der Spalten pro Seite
  operator_session: Sitzung
  order: Reihenfolge
  organization: Organisation
  parameter_name: Name
  parameter_value: Wert
  password_expiration: Passwortablauf
  plan: Plan
  precise_decimal_number: Präzisieren Sie die Anzahl der Dezimalstellen
  product: Produkt
  products:
    export: Liste der Produkte im CSV-Format exportieren
    import: CSV-Datei importieren
  profile: Profil
  reasons: Begründung für Änderungen
  repetition: Wiederholung
  repetition_type: Wiederholungstyp
  report_status: Berichtsstatus
  reset_automatically: Automatische Zurücksetzung
  roles: Rollen & Berechtigungen
  roles_access: Zugänglich für
  roles_cannot_delete_description: >-
    {amount} Benutzer besitzen diese Rolle. Bitte weisen Sie ihnen eine andere
    Rolle zu oder löschen Sie sie zuerst.
  roles_cannot_delete_title: Rolle kann nicht gelöscht werden
  roles_change-status: Status ändern
  roles_confirm_delete_description: >-
    Durch die Bestätigung wird diese Rolle dauerhaft gelöscht. Diese Aktion kann
    nicht rückgängig gemacht werden.
  roles_confirm_delete_title: Löschung bestätigen?
  roles_create: Erstellen
  roles_delete: Archivieren
  roles_delete_role: Löschen
  roles_edit_name: Namen bearbeiten
  roles_modify: Bearbeiten
  roles_new: Neue Rolle
  roles_read: Lesen
  roles_ressource: Ressource
  same_as_tolerance: Gleich wie Toleranz
  sampling: Stichprobe
  save_status: Speichern
  search_user_placeholder: Suchen und fügen Sie neue Mitglieder hinzu
  settings_error: Ein Problem ist beim Verbinden mit dem Server aufgetreten
  starting_time: Startzeit
  status_color: Farbe
  status_icon: Symbol
  status_name: Name
  status_saved: Der Status wurde gespeichert
  step_tag: Schrittbezeichnung
  team: Team
  team_name: Teamname
  theTeam: Das Team
  tolerance: Toleranz
  type: Typ
  unit: Einheit
  units_of_measure: Maßeinheiten
  update_group: Gruppe bearbeiten
  update_lookup: Zelle bearbeiten
  update_password_message_description: Der Benutzer muss sich mit dem neuen Passwort erneut anmelden
  update_password_message_title: Änderung des Passworts bestätigen?
  update_product: Produkt aktualisieren
  update_setting_error_message: Ein Fehler ist aufgetreten. Die Einstellung wurde nicht aktualisiert.
  update_setting_success_message: Einstellung erfolgreich aktualisiert
  update_setting_tolerance_error_message: Die Toleranz kann nicht geändert werden
  update_workplace: Arbeitsplatz aktualisieren
  users: Benutzer
  users_and_access: Konten und Zugriffe
  value: Wert
  value_exists: Dieser Name wird bereits verwendet
  value_tolerance: Toleranzwert
  workflow: Workflow
  workplaces:
    export: Liste der Arbeitsplätze im CSV-Format exportieren
    import: CSV-Datei importieren
sidebar:
  documents: Dokumente
  handle_documents: Meine Dokumente verwalten
  incidents: Vorfälle
  manage_production: Meine Produktion steuern
  manage_projects: Meine Projekte verwalten
  media_library: Medienbibliothek
  performance_analysis: Meine Leistung analysieren
  performance_analysis_production: Produktion
  performance_analysis_quality: Qualität
  production_monitoring: Produktionsüberwachung
  incident_form: Vorfallformulare
  production_order_monitoring: Auftragsüberwachung
  workplace_documents_monitoring: Dokumentenüberwachung
simulation:
  empty_simulation: Sie haben derzeit keine Simulationen. Sie können mit dem Erstellen beginnen.
  new_simulation: Neue Simulation
status:
  created: Der Status wurde erfolgreich erstellt
  deleted: Der Status wurde erfolgreich gelöscht
  status: Status
  statuses: Status
  updated: Der Status wurde erfolgreich aktualisiert
step:
  absolute_value: Absolutwert
  add_condition: Bedingung hinzufügen
  add_file: Datei hinzufügen
  add_one_or_multiple_files: Eine oder mehrere Dateien hinzufügen
  answer_nok: Nok
  answer_ok: Ok
  boolean: OK / NOK
  checkbox: Kontrollkästchen
  create_answer: Antwort erstellen
  customized_answer: Benutzerdefinierte Antwort
  delete_step_description: >-
    Das Löschen dieses Blocks ist nicht möglich, da er von einem Berechnungsblock abhängt
  delete_step_title: Schritt kann nicht gelöscht werden
  description_placeholder: Eine Beschreibung eingeben
  done: Fertig
  file: Datei
  file_upload_issue: Es gibt ein Problem beim Hochladen von Dateien
  link: Link
  list: Auswahl
  mandatory: Erforderlich
  double_check: Doppelcheck
  margin: Marge
  means: Messmittel
  measure: Messung
  measure_max: Max
  measure_min: Min
  measure_target: Ziel
  measure_tolerance: +/-
  measure_unit: Einheit
  multiple_selections: Mehrfachauswahl
  name_placeholder: Einen Namen eingeben
  napossible: Nicht anwendbar
  no_step_available: Kein Schritt verfügbar
  percentage: Prozentsatz
  select_condition_item_in_list: Eine Antwort auswählen...
  select_condition_step_in_list: Die Schritte zum Anzeigen auswählen...
  set_value: Definierter Wert
  take: Mal
  uncertainty: Ungewissheit
  preview_calculation: Vorschauberechnung
  create_calculation: Erstellen Sie eine Berechnung
  incorrect_calcul: Rechenfehler
  delete_all: Alles löschen
  requested_entries: Angeforderte Einträge
  calcul_preview: Berechnungsvorschau
  calculation_step: Berechnungsblock
  block: Block
  mathematical_operators: Mathematische Operatoren
  create_calcul_placeholder: >-
    Erstellen Sie Ihre Berechnung, indem Sie erforderliche Eingaben und
    mathematische Operatoren hinzufügen.
  fill_calcul_btn: Machen Sie eine Berechnung
  fill_calcul_title: Füllen Sie die folgenden Felder aus, um das Berechnungsergebnis zu erhalten
template:
  archive: Vorlage archivieren
  archive_template: Archivieren
  are_you_sure_edit: >-
    Die Bearbeitung dieser Vorlage wird alle zugehörigen Dokumente ändern. Sind
    Sie sicher, dass Sie fortfahren möchten?
  create: Neue Vorlage
  delete_confirmation_message: Möchten Sie diese Vorlage wirklich archivieren?
  get_more: '{ count } Vorlage laden'
  load_more: Mehr Vorlagen laden
  modify: Vorlage bearbeiten
  new_template: Neue Vorlage
  publish_template: Veröffentlichen
  reference: Referenz
  search: Vorlage suchen
  template_delete_used_reference: >-
    Das Archivieren der Vorlage wird irreversible Änderungen an den folgenden
    Dokumenten bewirken:
  template_name: Vorlagenname
  template_update_documents_error_message: >-
    Einige Dokumente wurden nicht ordnungsgemäß aktualisiert. Bitte versuchen
    Sie es erneut.
  template_update_error_message: Beim Speichern der Vorlage ist ein Fehler aufgetreten
  template_update_message: Die Vorlage wurde erfolgreich aktualisiert
  template_used_reference: >-
    Eine Änderung an der Vorlage ist nicht rückgängig zu machen und wird
    folgende Dokumente beeinflussen:
  unlink_template: Entkoppeln
trigger:
  day: Tag
  production_order: Fertigungsauftrag
  team: Team
user:
  active: Aktiv
  automatic_deactivation: Automatische Deaktivierung
  by: von
  client: Kunde
  confirmation_description_activate_user: Der Benutzer wird wieder Zugriff auf JUNO haben.
  confirmation_description_desactivate_user: >-
    Der Benutzer wird keinen Zugriff mehr auf JUNO haben, sobald sein Konto
    deaktiviert ist.
  confirmation_title_activate_user: Sind Sie sicher, dass Sie das Konto von {name} reaktivieren möchten?
  confirmation_title_desactivate_user: Möchten Sie das Konto von {name} wirklich deaktivieren?
  deactivated_successfully: Dieses Konto wurde erfolgreich deaktiviert
  desactivate: Deaktivieren
  disable_account: Konto deaktivieren
  disabled: Deaktiviert
  disabled_at: Deaktiviert am
  disabled_automatically_at: Automatisch deaktiviert am
  edit_information: Informationen bearbeiten
  email: E-Mail
  language: Sprache
  modify_password: Passwort ändern
  nfc_authentication_status: NFC-Authentifizierungsstatus
  nfc_code: NFC-Code
  nfc_status: NFC-Status
  nfc_text_validity: NFC-Gültigkeit testen
  password: Passwort
  personal_information: Persönliche Informationen
  reactivate: Reaktivieren
  reactivate_account: Konto reaktivieren
  reactivated_successfully: Dieses Konto wurde erfolgreich reaktiviert
  role: Rolle
  role_attribution_mandatory: Die Zuweisung einer Rolle ist obligatorisch
  site: Standort
  status: Status
  temporary: Temporär
  temporary_account: Temporäres Konto
  unknown_creator: Unbekannter Ersteller
  updated: Der Benutzer wurde aktualisiert
  site_attribution_mandatory: ''
  user_certified: Benutzerzertifizierung
  operator_certified: Zertifizierter Betreiber
  chain_manager_certified: Zertifizierter Manager
users:
  get_more: '{ count } Benutzer laden'
  load_more: Mehr Benutzer laden
  readonly_mode: Nur-Lesen-Modus
validate_nc: NC validieren
validations:
  document_category: Die Kategorie darf nicht leer sein
  document_description: Das Beschreibungsfeld ist erforderlich
  document_name: Das Namensfeld ist erforderlich
  minimum_characters: Bitte geben Sie mindestens {length} Zeichen ein
views:
  add: Eine Ansicht erstellen
  delete: Ansicht löschen
  delete_confirmation_message: Möchten Sie diese Ansicht wirklich löschen?
  delete_modal_title: Ansicht löschen
  description: Beschreibung der Ansicht
  error_create: Beim Erstellen der Ansicht ist ein Fehler aufgetreten
  error_delete: Beim Löschen der Ansicht ist ein Fehler aufgetreten
  error_empty_title: Der Titel darf nicht leer sein
  error_update: Beim Speichern der Ansicht ist ein Fehler aufgetreten
  filters: Aktive Filter
  get_more_reports: '{ count } Bericht laden'
  load_more_reports: Mehr Berichte laden
  name: Ansichtsname
  new_vue_template_description: Geben Sie hier eine Beschreibung ein
  new_vue_template_title: Neue Ansicht
  no_filters_selected: Kein Filter ausgewählt
  no_group: Keine
  save: Ansicht speichern
  save_view: Speichern
  saved_views: Gespeicherte Ansichten
  title: Überwachung
  view_all: Alle anzeigen
  view_created: Eine neue Ansicht wurde erfolgreich erstellt
  view_delete: Die Ansicht wurde erfolgreich gelöscht
  view_updated: Die Ansicht wurde erfolgreich aktualisiert
welcome_url:
  analytics: Analyse
  conception: Konzeption
  designer_space: Raumüberwachung
  library: Bibliothek
  operation: Betrieb
  operator_free: Arbeitsplätze
  operator_selection: Auswahl des Arbeitsplatzes
  operator_space: Produktionsfläche
  select_option: Auswählen...
  space: Bereich
  supervision: Überwachung
  welcome_page: Willkommensseite
workflows:
  add: Schritt hinzufügen
  add_description: Beschreibung hinzufügen
  associated_permissions: Verbundene Berechtigungen
  associated_status: Verbundener Status
  built_in: Standard-Workflow
  create: Workflow erstellen
  incident: Vorfall
  created: Der Workflow wurde erfolgreich erstellt
  customizable: Anpassbare Workflows
  default: Standard-Workflow
  default_alert_step_1: Neue Warnung
  default_alert_step_2: Warnung in Bearbeitung
  default_alert_step_3: Warnung behandelt
  default_document_step_1: Dokument in Bearbeitung
  default_document_step_2: Veröffentlichtes Dokument
  default_of_step_1: Neuer Fertigungsauftrag
  default_of_step_11: Freigegebener Fertigungsauftrag
  default_of_step_2: Fertigungsauftrag in Bearbeitung
  default_of_step_3: Fertigungsauftrag abgeschlossen
  default_operations_step_1: Neue Operation
  default_operations_step_2: Operation in Bearbeitung
  default_operations_step_3: Operation abschließen
  default_report_step_1: Neuer Bericht
  default_report_step_2: Bericht in Bearbeitung
  default_report_step_3: Bericht abgeschlossen
  description: Beschreibung
  error: Ein Fehler ist beim Erstellen/Aktualisieren des Workflows aufgetreten
  error_inputs: Bitte füllen Sie die Pflichtfelder aus, um den neuen Schritt zu bestätigen
  name: Name des Workflows
  new_workflow_title: Neuer Workflow
  no_workflow: Kein Workflow
  not_found: Kein Workflow gefunden
  not_found_description: >-
    Erstellen Sie einen neuen Workflow, indem Sie auf die Schaltfläche oben
    rechts auf dem Bildschirm klicken
  save: Workflow speichern
  search_status: Status suchen...
  select_option: Status auswählen
  select_workflow: Workflow auswählen
  select_workflow_type: Wählen Sie einen Workflow-Typ aus, um einen neuen zu erstellen
  templates: Vorlagen
  title: Workflow
  type: Typ
  type_alerts: Warnung
  type_documents: Dokument
  type_of: Fertigungsauftrag
  type_operations: Operation
  type_reports: Bericht
  type_select: Typ auswählen
  unnamed: Unbenannter Workflow
  updated: Der Workflow wurde erfolgreich aktualisiert
workorder:
  complete: Operation abschließen
  confirm_modal_description: >-
    Sie sind dabei, die Operation abzuschließen. Haben Sie alle Ihre Dokumente
    ordnungsgemäß ausgefüllt?
  confirm_modal_title: Operation abschließen und schließen
  set_operation_in_progress: Operation als in Bearbeitung setzen
incident_form:
  new: Neues Formular
  new_step: Neuer Schritt
  title: Vorfallformulare
  my_forms: Meine Formulare
  remove_branch: Sind Sie sicher, dass Sie diesen Zweig löschen möchten?
  remove_branch_description: Diese Aktion kann nicht rückgängig gemacht werden. Alle Werte, die mit diesem Zweig verbunden sind, gehen verloren.
simulator:
  generate: Generieren
  derivation: Ableitung
  derivation_data: Ableitungsdaten
  available_only_for_pilot_mode: Nur im Pilotmodus verfügbar
projects:
  create_new: Neues Projekt
  all_projects: Alle Projekte
  assignees: Zugewiesene
  dates: Daten
  deadline: Frist
  priority:
    title: Priorität
    low: Niedrig
    medium: Mittel
    high: Hoch
    tbd: Noch zu erledigen
  progress: Fortschritt
  budget: Budget
  title: Projekt
  information: Projektinformationen
  localisation: Standort
  new: Neues Projekt
  assignees_placeholder: Kein zugewiesener
  name_placeholder: Geben Sie dem Projekt einen Namen
  budget_placeholder: Geben Sie dem Projekt einen Kosten
  description_placeholder: Worum geht es in diesem Projekt?
  deadline_placeholder: Kein Datum
  priority_placeholder: Wie hoch ist die Priorität dieses Projekts?
  localisation_placeholder: Wo befindet sich dieses Projekt?
  demo:
    project_1_title: Verbesserung der Maschinenverfügbarkeit
    project_1_description: "Die Effizienz und Qualität des Umstellprozesses verbessern, um Ausfallzeiten, Qualitätsmängel und Produktivitätsverluste zu reduzieren"
    project_2_title: Ausschussrate senken
    project_3_title: Lieferzeit verkürzen
  filters:
    day_hour: Tag/Stunde
    week_day: Woche/Tag
    month_day: Monat/Tag
    year_month: Jahr/Monat
    year: Jahr
  actions:
    all_actions: Alle Aktionen
    activity: Aktivität
    create_new: Neue Aktion
    deliverable: Ergebnis
    add_delivery_description_placeholder: Beschreibung eingeben...
    add_delivery_file: Datei anhängen
    activity_all: Alle
    activity_comment: Kommentare
    activity_status: Statusverlauf
    add_new: Eine Aktion hinzufügen
    demo:
      action_1_title: Schulung des Personals
      action_1_description: "Datenerfassung: Sammeln von Daten über Ausfallzeiten und Fehler bei früheren Umstellungen."
      action_2_title: Zusätzliche Qualitätskontrollen einführen
      action_3_title: Manuelle und sich wiederholende Aufgaben im Bestellprozess automatisieren
      action_4_title: "Einbeziehung der Bediener in die Identifizierung und Lösung von Problemen im Zusammenhang mit Ausschuss"
      action_5_title: "Kundenfeedback zur Lieferung einholen und Verbesserungsmöglichkeiten identifizieren"
      action_6_title: "Analyse der Ausfallzeiten"
      action_7_title: Präventive Wartung einführen
      action_8_title: Verbesserung der Ersatzteilverwaltung
      action_9_title: "Optimierung der Lieferkette"
    project: Projekt
    title: Projektaktionen
    history:
      assigned: hat die Aktion zugewiesen an
      commented: kommentierte
      created: hat die Aktion erstellt
      updated: hat den Status der Aktion geändert
    project_placeholder: Fügen Sie die Aktion Projekten hinzu
    category_placeholder: Keine Kategorie
    name_placeholder: Geben Sie der Aktion einen Namen
    no_description: Keine Beschreibung angegeben.
    description_placeholder: Worum geht es in dieser Aktion?
